import { createRouter, createWebHashHistory } from "vue-router";
import { notification } from 'ant-design-vue'

const UserLogin = () => import("@/pages/UserLogin.vue");
const MenuContent = () => import("@/pages/MenuContent.vue");
const PersonalStation = () => import("@/pages/PersonalStation.vue");
const WXLogin = () => import('@/pages/WXLogin/WXLogin.vue')
const AutoLogin = () => import("@/pages/AutoUserLogin.vue")

// const NotificationList = () => import("@/pages/message-management/NotificationList.vue");

const router = createRouter({
	history: createWebHashHistory(),
	linkActiveClass: "active",
	routes: [
		{
			path: "/",
			redirect: "/login",
		},
		{
			path: "/login",
			name: "login",
			component: UserLogin,
		},
		{
			path: "/autologin",
			name: "autologin",
			component: AutoLogin,
		},
		{
			path: '/wxLoginCallback',
			name: 'wxLoginCallback',
			component: WXLogin
		},
		{
			path: "/home",
			name: "home",
			redirect: "/home/personal",
			component: MenuContent,
			children: [
				{
					path: "personal",
					name: "PersonalStation",
					component: PersonalStation,
				},
			],
		},
	],
});

// 路由拦截
router.beforeEach((to, from, next) => {
	if (to.path === '/login' || to.path === '/autologin') return next()
    const _token = localStorage.getItem('feiliks-token')
    if (to.path !== '/wxLoginCallback' && to.path !== '/WW_verify_wquolckM00obIJ3D.txt' && !_token) {
		console.log('to.path1',to.path)
        notification.error({
            message: '您还未登录！',
            description: '请先登录',
        })
        return next('/login')
    }
	console.log('to.path2',to.path)
    next()
})

export default router
