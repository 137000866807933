import { createStore } from 'vuex'

// 创建一个新的 store 实例
export const store = createStore({
  state () {
    return {
        unreadCount: 0,
        userCode: "123123123",
        loading: true,
        lang: localStorage.getItem('feiliks-language') || 'CN'
    }
  },
  mutations: {
    setUnreadCount (state, value) {
      state.unreadCount = value
    },
    setUserCode (state, value) {
      state.userCode = value
    },
    setLoading (state, value) {
      state.loading = value
    },
    setLang (state, value) {
      state.lang = value
    }
  }
})

export default store