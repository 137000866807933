export function setCache(token, appName, userInfo, updatePwd) {
    localStorage.setItem("feiliks-token", token);
    localStorage.setItem("feiliks-theme", "light");
    if (localStorage.getItem('feiliks-language') === 'zh_CN') {
        localStorage.setItem('feiliks-language', 'CN')
    }
    localStorage.setItem("feiliks-language", localStorage.getItem("feiliks-language") || "CN");
    localStorage.setItem("feiliks-device", "pcweb");
    localStorage.setItem("feiliks-user", JSON.stringify(userInfo));
    localStorage.setItem("feiliks-perm", JSON.stringify([]));
    localStorage.setItem("feiliks-perm-button", JSON.stringify({}));
    localStorage.setItem("feiliks-perm-data", JSON.stringify({}));
    localStorage.setItem("feiliks-login-app", appName);
    localStorage.setItem("feiliks-force-updatePwd", updatePwd);
}
export function cleareCache() {
    localStorage.clear();
}
