/* eslint-disable no-undef */
import axios from "axios";
import { cleareCache } from "../utils/buffer";
import router from "@/router";

axios.defaults.headers["Content-Type"] = "application/json";

const axiosInstance = axios.create({
	timeout: 180000,
	headers: {
		"feiliks-token": localStorage.getItem("feiliks-token") || "",
		"feiliks-language": localStorage.getItem("feiliks-language") || "CN",
		"feiliks-device": localStorage.getItem("feiliks-device") || "pcweb",
	},
});

axiosInstance.interceptors.request.use(
	(config) => {
		if (localStorage.getItem("feiliks-token")) {
			// config.headers.Authorization = localStorage.getItem("feiliks-token");
			config.headers['feiliks-token'] = localStorage.getItem("feiliks-token");
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return {
			code: response.data.code,
			data: response.data,
		};
	},
	(error) => {
		console.log(error,444);
		const status = error.response?.status;
		if (status === 401) {
			console.log("token失效");
			cleareCache();
			router.push({
				name: "login",
			});
		}
		return (error);
	}
);
export default axiosInstance;
