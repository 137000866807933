import axiosInstance from '@/services/axiosUtils'

// 用户相关接口
const userApi = {
  // 登陆
  Login (params) {
    return axiosInstance.post('/jeecg-boot/sys/login', params)
  },
  // 登出
  loginOut () {
    return axiosInstance.get('/jeecg-boot/sys/logout')
  },
  // 获取菜单栏数据
  GetMenuData () {
    return axiosInstance.get('/jeecg-boot/sys/permission/getCurrentUserPermission')
  },
  // 密码过期
  queryPasswordExpirationReminder () {
    return axiosInstance.get('/jeecg-boot/sys/user/queryPasswordExpirationReminder')
  },
  // 用户菜单
  getCurrentUserPermission () {
    return axiosInstance.get('/jeecg-boot/sys/permission/getCurrentUserPermission')
  },

  // 获取用户中心安全策略的密码安全策略配置
  getPwdConfig (params) {
    return axiosInstance({
      url: '/jeecg-boot/sys/securityPolicy/queryObject',
      params,
      method: 'get'
    })
  },

  // 获取生成企业微信登录二维码相关信息
  getThirdLoginConfig (data) {
    return axiosInstance({
      url: '/jeecg-boot/sys/thirdLogin/wechat_enterprise/getThirdLoginConfig',
      data,
      method: 'post'
    })
  },

  // 获取国际化语言
  getLanguageName (params) {
    return axiosInstance({
      url: '/CommonDomain/CommonSelectService/findAllLang/v1',
      params,
      method: 'get'
    })
  }
}

export default userApi
