import { createI18n } from "vue-i18n";
import enLocale from "./en_GB";
import zhLocale from "./zh_CN";

const msg = {
	EN: {
		...enLocale,
	},
	CN: {
		...zhLocale,
	},
	zh_CN: {
		...zhLocale
	}
};

export default createI18n({
	legacy: false,
	globalInjection: true,
	locale: localStorage.getItem("feiliks-language") || "CN",
	messages: msg,
});
