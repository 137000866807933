<template>
  <a-config-provider :locale="locale === 'EN' ? enUS : zhCN">
    <router-view/>
  </a-config-provider>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from "vue"
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const locale = computed(() => store.state.lang)
    return {
      locale,
      zhCN,
      enUS
    }
  }
}
</script>

<style lang="less">
body {
    padding: 0;
    margin: 0;
}
</style>
