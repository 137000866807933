import NProgress from 'nprogress'
import { notification } from 'ant-design-vue'
import router from '@/router/index'
import user from '@/api/user'

NProgress.configure({ showSpinner: false })
const bathPath = '/order'
const homePage = '/orderCenter/orderList'
const applicationOrderCode = 'orderCenter'
const applicationPersonalWorkbenchCode = 'personalWorkbench'
const whiteList = ['/login', '/wxLoginCallback', '/autologin']
const otherSystemPath = [
    '/personalWorkbench/#/personalWorkbench/toDoList'
]
const permissionInfoStore = {
    currentPermissionList: [],
    isLoadPermission: false
}

/**
 * 判断是否有权限
 * @param path
 * @param permissionList
 */
function hasPermission (path, permissionList) {
    if (permissionList && permissionList.length) {
        return !!permissionList.find(item => {
            return item.path === path
        })
    }
    return false
}

/**
 * 拉平权限数组
 * @param permissionList
 */
function flatPermissionList (permissionList) {
    let ret = []
    permissionList.forEach((permission) => {
        const { children } = permission
        if (children && children.length) {
            ret = [...ret, ...flatPermissionList(children)]
        } else {
            ret.push(permission)
        }
    })
    return ret
}

/**
 * 获取有权限的第一个页面
 * 默认首页有权限，直接取用默认首页（弃用）
 * 默认首页没有权限，取有权限的第一个页面
 * @param basePath
 * @param homePage
 * @param permissionList
 */
function getPermissionHomePage(basePath, homePage, permissionList) {
    if (permissionList && permissionList.length) {
        let currentPermission = permissionList[0] || {}
        const currentPermissionPath = currentPermission.path
        return currentPermissionPath || null
    } else {
        return null
    }
}

const defaultTokenKey = 'feiliks-token'
const getToken = (key = defaultTokenKey) => {
    return localStorage.getItem(key)
}
const setToken = (key = defaultTokenKey, value) => {
    localStorage.setItem(key, value)
}
const clearToken = () => {
    localStorage.clear()
}

router.beforeEach(async (to, from, next) => {
    NProgress.start()

    const hasToken = getToken()

    // 已经登录
    if (hasToken) {
        // const { data: { result: { menuPermission, menu } }} = await user.getCurrentUserPermission()
        const res = await user.getCurrentUserPermission()
        const originPermission = res.data || {}
        localStorage.setItem('originPermission',JSON.stringify(res.data))
        const menuPermission = originPermission?.result.menuPermission
        const menu = originPermission?.result.menu
        const button = originPermission?.result.button
        permissionInfoStore.currentPermissionList = [
            // 个人工作台权限
            ...(menuPermission[applicationPersonalWorkbenchCode] || []),
            // 订单权限
            ...(menuPermission[applicationOrderCode] || []),
            // 报表中心权限
            ...(menuPermission.ReportCenter || [])
        ].filter(item => item.topPermissionId)
        permissionInfoStore.isLoadPermission = true
        const buttonPermission = button.map((item) => item.code)
        localStorage.setItem('currentPermissionList', JSON.stringify(permissionInfoStore.currentPermissionList))
        localStorage.setItem('menu', JSON.stringify(menu))
        localStorage.setItem('isLoadPermission', '1')
        localStorage.setItem('buttonPermission', JSON.stringify(buttonPermission))

        // 默认进入第一个有权限的地址
        if (to.path === '/login' || to.path === '/home' || to.path === '/home/personal' || to.path === '/autologin') {
            // 进入首页
            const ret = getPermissionHomePage(bathPath, homePage, flatPermissionList(menu))
            // 其他系统的地址
            if (ret) {
                next(false)
                location.href = `${location.origin}${ret}`
            } else {
                // 终止跳转
                notification.warning({
                    message: '你无权访问此页面'
                })
                next(false)
            }
        }
        // 正常路径
        else {
            try {
                if (hasPermission(bathPath + to.path, permissionInfoStore.currentPermissionList)) {
                    next()
                } else {
                    // 终止跳转
                    notification.warning({
                        // message: '你无权访问此页面'
                        message: `你无权访问${to.meta.title || '此页面'}`
                    })
                    next(false)
                }
            } catch (error) {
                clearToken()
                // 错误提示
                // todo
                next(`/login?redirect=${to.path}`)
                NProgress.done()
            }
        }
    }
    // 没有登录
    else {
        // 访问路径在白名单中
        if (whiteList.indexOf(to.path) > -1) {
            next()
        }
        // 访问路径不在白名单中
        else {
            next(`/login?redirect=${to.path}`)
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
