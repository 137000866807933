import { createApp } from "vue";
import { registerMicroApps, start } from 'qiankun';
import App from "./App.vue";
// import dayjs from 'dayjs'
import router from "./router";
import i18n from "./locales";
import axiosInstance from "./services/axiosUtils";
import store from "./store/store";
import { Button, Form, Input, Divider, Avatar, Dropdown, Menu, Popover, Modal, Table, Transfer, InputNumber, Switch, Breadcrumb,
	Select, Upload, Popconfirm, message, ConfigProvider, Steps } from "ant-design-vue";
import '@/icons'
import Components from '@/components'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import '@/permission'

dayjs.locale('en')

message.config({
	top: `120px`,
	duration: 3,
	maxCount: 1,
});


const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.appName = "个人工作台";

console.log(window.__POWERED_BY_QIANKUN__, process.env)
app.use(Button)
	.use(Form)
	.use(Input)
	.use(InputNumber)
	.use(Divider)
	.use(Avatar)
	.use(Menu)
	.use(Dropdown)
	.use(Popover)
	.use(Modal)
	.use(Table)
	.use(Transfer)
	.use(Switch)
	.use(Breadcrumb)
	.use(Select)
	.use(Upload)
	.use(Popconfirm)
	.use(ConfigProvider)
	.use(Components)
	.use(Steps)
	.use(router)
	.use(store)
	.use(i18n)
	.mount('#appBase'); // 为了避免根id#app与其他DOM冲突，需要限制查找范围

const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);
registerMicroApps([
	// 中台子系统
	{
		name: 'common',
		entry: process.env.NODE_ENV == 'production' ? '/common/' : 'http://localhost:8080',
		container: '#appCommon',
		activeRule: getActiveRule('#/common'),
	},
	{
		name: 'product',
		entry: process.env.NODE_ENV == 'production' ? '/product/' : 'http://localhost:8082',
		container: '#appProduct',
		activeRule: getActiveRule('#/production'),
	},
	{
		name: 'customer',
		entry: process.env.NODE_ENV == 'production' ? '/customer/' : 'http://localhost:8083',
		container: '#appCustomer',
		activeRule: getActiveRule('#/customer'),
	},
	{
		name: 'feiliks-personal-workbench',
		entry: process.env.NODE_ENV == 'production' ? '/personalWorkbench/' : 'http://localhost:8083',
		container: '#appPersonalWorkbench',
		activeRule: getActiveRule('#/personalWorkbench'),
	},
	{
		name: 'user',
		entry: process.env.NODE_ENV == 'production' ? '/user/' : 'http://localhost:8083',
		container: '#appUser',
		activeRule: getActiveRule('#/user'),
	},
	{
		name: 'order',
		entry: process.env.NODE_ENV == 'production' ? '/order/' : 'http://localhost:8083',
		container: '#appOrder',
		activeRule: getActiveRule('#/order'),
	},
	{
		name: 'train',
		entry: process.env.NODE_ENV == 'production' ? '/train/' : 'http://localhost:8083',
		container: '#appTrain',
		activeRule: getActiveRule('#/train'),
	},
	{
		name: 'portal',
		entry: process.env.NODE_ENV == 'production' ? '/portal/' : 'http://localhost:8083',
		container: '#appPortal',
		activeRule: getActiveRule('#/portal'),
	},
	// 飞力达内部系统

	// 运输中心
	{
		name: 'tms',
		entry: process.env.NODE_ENV == 'production' ? '/tms/' : 'http://localhost:8082',
		container: '#appTms',
		activeRule: getActiveRule('#/tms'),
	},
	// 企业关务
	{
		name: 'cms',
		entry: process.env.NODE_ENV == 'production' ? '/cms/' : 'http://localhost:8083',
		container: '#appCms',
		activeRule: getActiveRule('#/cms'),
	},
	// 空运中转仓
	{
		name: 'twarehouse',
		entry: process.env.NODE_ENV == 'production' ? '/twarehouse/' : 'http://localhost:8083',
		container: '#appTwarehouse',
		activeRule: getActiveRule('#/twarehouse'),
	},
	// 核查核销
	{
		name: 'cvs',
		entry: process.env.NODE_ENV == 'production' ? '/cvs/' : 'http://localhost:8083',
		container: '#appCvs',
		activeRule: getActiveRule('#/cvs'),
	}
], {
	beforeLoad: app => {
		console.log('before load app.name====>>>>>', app.name)
	},
	beforeMount: [
		app => {
			console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
		}
	],
	afterMount: [
		app => {
			// 加载微应用前，进度条加载完成
			console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
		}
	],
	afterUnmount: [
		app => {
			console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
		}
	]
});

// 启动 qiankun
start({
	prefetch: true, // 开启预加载
	sandbox: {
		strictStyleIsolation: false,
		experimentalStyleIsolation: true,
	},
});

